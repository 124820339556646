import { Component, ErrorInfo } from 'react';
import { Navigate, useRouteError, useLocation } from 'react-router-dom';

export const RouterError = () => {
    const error = useRouteError();
    return (
        <Navigate to="/practice/error" state={{ error }} />
    )
}


export class ErrorBoundary extends Component<{ children?: React.ReactNode }, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorText />
        }

        return this.props.children;
    }
}
export const ErrorText = () => {
    const { state } = useLocation();

    return <div>
        <h1>Something went wrong.</h1>
        <div>Unable to load the information you are requesting, possibly because of an internet connection issue. Try refreshing the page.</div>
        {state && state.error && <div style={{ margin: "20px" }}>Message: {state.error.message}</div>}
    </div>
}
