//declare var require: any
//var React = require('react');
//var ReactDOM = require('react-dom');
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { OnlineApptInfo } from "./freebusy";
import { getStorage, setStorage } from "./app"
//import { Last } from "react-bootstrap/esm/PageItem";
interface UserContext {
    userInfo: ReturnType<typeof useSigninState>,
    userData: [UserData | null, React.Dispatch<React.SetStateAction<UserData | null>>]
}

export interface UserInfoState {
    signedIn: boolean,
    email: string,
    picURL: string,
    id_token?: string,
    tokenExp: number
}
export interface UserData {
    appts: OnlineApptInfo[],
    altEmail: string[]
}
//A shared context that stores the app's user sign-in state
export const UserContext = createContext(null as null | UserContext);
UserContext.displayName="UserContext"
export function useVacationInfo() {
    const [statusJson, setStatusJson] = useState<string>(statusSnapshot);
    useEffect(() => { void statusLoader().then(r => setStatusJson(r as string)) }, []);
    const returnVal = useMemo(() => {
        return JSON.parse(statusJson) as { vacation: Array<[number, number]>, covid: string };
    }, [statusJson]);
    return returnVal;
}
const SchedAvailContext = createContext(([false, () => null]) as [boolean, React.Dispatch<React.SetStateAction<boolean>>])
SchedAvailContext.displayName="ScheduleAvailable"
const SchedAvailProvider = ({ children }: { children: ReactNode }) => {
    const isSchedAvail = (document.cookie.search(/lastsched/) !== -1 || getStorage("schedAvail") === 'true');
    const [schedAvail, setSchedAvail] = useState(isSchedAvail);
    const returnState: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useMemo(() => [schedAvail, setSchedAvail], [schedAvail]);
    const schedAvailChange = schedAvail != isSchedAvail;
    useEffect(() => {
        if (schedAvailChange) { setStorage("schedAvail", "true"); setSchedAvail(true); }
    }, [schedAvailChange]);
    return <SchedAvailContext.Provider value={returnState}>
        {children}
    </SchedAvailContext.Provider>;
}
//UserProvider wraps the main app to provide access to the shared context
export const UserProvider = ({ children, session }: { children: ReactNode, session: Promise<string | null> }) => {

    const userInfo = useSigninState();
    const [userDataVal, setUserData] = useState(null as UserData | null);
    const userData: [UserData | null, React.Dispatch<React.SetStateAction<UserData | null>>] = useMemo(() => [userDataVal, setUserData], [userDataVal])
    const contextVal = useMemo(() => ({
        userInfo,
        userData,
    }), [userData, userInfo])
    return (
        <UserContext.Provider value={contextVal}>
            <SchedAvailProvider>
                {children}
            </SchedAvailProvider>
        </UserContext.Provider>
    )
}
export function getVacationDays(vacArray: number[][]) {
    return vacArray.reduce((days, [beg, end]) => {
        let startDate = new Date(beg).setUTCHours(12, 0, 0, 0);
        const endDate = (end);
        while (startDate <= endDate) {
            days.add(startDate);
            startDate += 1000 * 3600 * 24;
        }
        return days;
    }, new Set<number>());
}
//custom Hook to gain access to userInfo in any component
export function useUserInfo(): UserContext {
    const c = useContext(UserContext);

    return c as UserContext;
}
export function UserInfo() {
    const [userInfo, setUserInfo] = useUserInfo().userInfo;
    const signOut = () => setUserInfo(Object.assign({}, userInfo, { signedIn: false }));
    //useEffect(() => { if (userInfo.tokenExp < new Date().getTime() && userInfo.signedIn) signOut() });
    return (
        <div id="aznSignIn" style={{ float: "right", paddingLeft: "15px" }}>
            <br />
            {!userInfo.signedIn ?
                <SigninLink /> :
                <>
                    {userInfo.picURL != '' ?
                        <img id="profileImg" style={{ float: "right", height: "36px" }} src={userInfo.picURL} /> : null}
                    {userInfo.email}
                    <a onClick={signOut} style={{ textDecorationLine: "underline", display: "block" }} href={"https://candell.auth.us-west-2.amazoncognito.com/logout?logout_uri=https://www.candell.org/practice/login&client_id=3gtshr83se3roi8a6f5hcl32iv"}>Sign out</a>
                </>
            }
        </div>
    )
}
export function SigninLink({ redirect, inline }: { redirect?: string, inline?: boolean }) {
    const loc = useLocation()
    const redirectState: string = btoa(redirect || loc.pathname);
    return (
        <a id="linktologin" style={inline ? { textDecorationLine: "underline" } : { textDecorationLine: "underline", clear: "both", display: "block" }} href={"https://candell.auth.us-west-2.amazoncognito.com/login?response_type=token&redirect_uri=https://www.candell.org/practice/login&client_id=3gtshr83se3roi8a6f5hcl32iv&state=" + redirectState}>Sign In</a>
    )
}
export function VacationInfo() {
    const { vacation } = useVacationInfo();
    const newVacJSONImpure = JSON.stringify(vacation.map((vacDates) => genVacationStr(vacDates, 30)));
    const [vacationStrJSON, setVacationStrJSON] = useState("[]");
    useEffect(() => setVacationStrJSON(newVacJSONImpure), [newVacJSONImpure]);
    const vacStrArr = JSON.parse(vacationStrJSON) as Array<string>;
    return <>
        {vacStrArr.map((vacStr, i) => <div style={{ textIndent: "-2.5rem", marginLeft: "2.75rem" }} className='redtext' key={i}>{vacStr}</div>)}
    </>
}
export function CovidComp() {
    const { covid: covidStr } = useVacationInfo();
    const todayStr = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles', dateStyle: "full" });
    //use state and effect to make component pure
    const [todayStrState, setTodayStrState] = useState("");
    useEffect(() => {
        if (covidStr.indexOf('<date>') !== -1) setTodayStrState(todayStr)
    }, [covidStr, todayStr]);

    const parts = (covidStr).replace('<date>', todayStrState).split('</b>')
    return <div>
        {parts.map((p, i) => {
            if (p.slice(0, 3) === '<b>')
                return (<b key={i} > {p.slice(3)}</b>);
            else
                return <span key={i}> {p} </span>
        })
        }
    </div>
}
export function useSchedAvail(): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
    const c = useContext(SchedAvailContext)
    return c;
}
const signedOut: UserInfoState = {
    signedIn: false,
    email: '',
    picURL: '',
    tokenExp: 0
};
function removeStoredSignin() {
    try {
        localStorage.removeItem('signinState');
    }
    catch {
        //
    }
}
function getInitSignin() {
    const localInfoJson = getStorage('signinState');
    const localInfo = localInfoJson ? (JSON.parse(localInfoJson) as UserInfoState) : signedOut;
    if (localInfoJson && localInfo.tokenExp < Date.now()) {
        removeStoredSignin();
        return signedOut;
    }
    return localInfo;
}
export function useSigninState(): [UserInfoState, (st: UserInfoState) => void] {
    const [userInfo, setUserInfo] = useState(getInitSignin);
    const setSignIn = useCallback((st: UserInfoState) => {
        if (st.signedIn && st.tokenExp > Date.now()) {
            setStorage('signinState', JSON.stringify(st));
            setUserInfo(st);
        }
        else {
            removeStoredSignin();
            setUserInfo(signedOut);
        }
    }, []);
    useEffect(
        () => {
            let timer1: number | string;
            if (userInfo.signedIn) {
                const tokenActiveMs = userInfo.tokenExp - Date.now();
                timer1 = setTimeout(() => setSignIn(signedOut), tokenActiveMs > 0 ? tokenActiveMs : 1) as unknown as number;
            }
            return () => {
                clearTimeout(timer1);
            };
        },
        [setSignIn, userInfo.signedIn, userInfo.tokenExp]
    );
    const returnState: [UserInfoState, (st: UserInfoState) => void] = useMemo(() => [userInfo, setSignIn], [setSignIn, userInfo]);
    return returnState;
}
const vacDateFmt = Intl.DateTimeFormat('en-US', { timeZone: 'America/Los_Angeles', weekday: 'short', month: 'short', day: 'numeric' });
function genVacationStr([startNum, endNum]: [number, number], cutoff: number): string {
    const nowNum = new Date().getTime();
    if (nowNum > endNum || startNum > nowNum + cutoff * 24 * 3600 * 1000)
        return '';
    const startStr = vacDateFmt.format(new Date(startNum)).replace(/\s/g, "\u00A0");
    const endStr = vacDateFmt.format(new Date(endNum)).replace(/\s/g, "\u00A0");
    //const endDay = endStr.split(',')[0];
    //if(endDay==='Monday')
    //adjust so that vacation comes off on Saturday instead
    let dispText = `NOTE: I will be out of the office`;
    if (startStr === endStr)
        dispText += ` on ${startStr}`;
    else {
        if (startNum > nowNum)
            dispText += ` from ${startStr}`;
        dispText += ` until ${endStr}`;
    }
    return dispText;
}
let lastStatusFetch: Promise<string> | null = null;
let fetching = false;
const statusCachePeriod = 1000 * 60 * 10;
const emptyStatus = { vacation: [] as Array<[number, number]>, covid: "" };
let statusSnapshot = JSON.stringify(emptyStatus);
const statusJsonUrl = "https://www.candell.org/data/status.json";
const getCachedStatus = () => {
    return caches.open("practice-json-cache")
        .then(c => c.match(statusJsonUrl))
}
void getCachedStatus().then(cacheResponse => {
    if (cacheResponse) {
        return cacheResponse.text().then(j => {
            if (!fetching) statusSnapshot = j;
            return j
        });
    }
})

function statusLoader() {
    return getCachedStatus()
        .then((cacheResponse: Response | undefined) => {
            if (cacheResponse) {
                const lastDate = Number(cacheResponse.headers.get("fetch-time") || "");
                if ((Date.now() - lastDate < statusCachePeriod)) {
                    return cacheResponse.text();
                }
            }
            if (!fetching) {
                fetching = true;
                setTimeout(() => fetching = false, 10 * 1000); //limit new fetch to every 10 seconds
                lastStatusFetch = fetch(statusJsonUrl, { cache: 'no-cache' })
                    .then(r => cacheJson(statusJsonUrl, r))
                    .then(r => r.text())
                    .then(r => statusSnapshot = r)
                    .catch(() => cacheResponse ? cacheResponse.text() : JSON.stringify(emptyStatus));
            }
            return lastStatusFetch;
        })
}
export async function cacheJson(url: string, r: Response, cacheTime?: number) {
    if (r.ok) {
        try {
            const cache = await caches.open('practice-json-cache');
            const respClone = r.clone();
            const headers = new Headers(r.headers);
            headers.append("fetch-time", String(cacheTime || Date.now()));
            const timestampResp = new Response(await respClone.blob(), {
                status: respClone.status,
                statusText: respClone.statusText,
                headers
            })
            await cache.put(url, timestampResp);
        }
        catch (ex) {
            console.error('caching failed; returning original response')
        }
        return r;
    }
    throw `response.ok != true for ${url}`;
}
