import { lazy, StrictMode, Suspense, useEffect, useRef, useState, createContext, Dispatch, SetStateAction, memo } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { createBrowserRouter, createRoutesFromElements, Location, Link, Navigate, NavLink, Outlet, Route, RouterProvider, useLocation, useNavigate, useNavigation, useParams, useSearchParams } from "react-router-dom";
import { ErrorBoundary, ErrorText, RouterError } from "./error";
import { SigninLink, UserProvider, useUserInfo, useSchedAvail } from "./UserInfo";
//import './app.css'
//import * as ReactGA from 'react-ga';
//const TRACKING_ID = 'UA-289077-1'
//ReactGA.initialize(TRACKING_ID);
export function getStorage(key: string) {
    try {
        const v = localStorage.getItem(key);
        return v;
    }
    catch {
        return null;
    }
}
export function setStorage(key: string, val: string) {
    try {
        localStorage.setItem(key, val);
    }
    catch {
        // localStorage failed
    }
}
let SessionId = getStorage("SessionId")
const practiceDir = '/practice/'
if (!SessionId) {
    SessionId = Math.random().toString(16).slice(2);
    setStorage("SessionId", SessionId)
}
const sessionEstablished: Promise<string | null> =
    fetch(practiceDir + "api/startSession",
        {
            mode: 'cors',
            method: "POST",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
            },

            body: JSON.stringify({ logText: 'New Session Started', schedAvail: document.cookie.search(/lastsched/) !== -1 || getStorage("schedAvail") })
        }).then(r => r.json(), () => []).then(
            () => SessionId,
            () => null)

export const DebugContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([false, () => null])
DebugContext.displayName = "DebugContext"

function PracticeApp() {
    const [debug, setDebug] = useState(false);
    return (
        <UserProvider session={sessionEstablished}>
            <>
                <UpgradeToast />
                <DebugContext.Provider value={[debug, setDebug] }>
                    <div id="wrapper">
                        <Logo />
                        <div className="banner" />
                        <NavBar />
                        <Outlet />
                        <div className="clearfix"></div>
                    </div>
                </DebugContext.Provider>
            </>
        </UserProvider>
    )
}

function UpgradeToast() {
    const [show, setShow] = useState(false);
    useEffect(() => {
        let mounted = true;
        // if (['localhost', '127'].indexOf(location.hostname) !== -1) window['isUpdateAvailable'] = new Promise((res) => setTimeout(() => res(true), 2000));
        if (window['isUpdateAvailable']) (window['isUpdateAvailable'] as Promise<boolean>).then((isAvail) => {
            if (isAvail && mounted) setShow(true);
        }).catch(() => null);
        return () => {
            setShow(false);
            mounted = false;
        }
    }, []);
    return (show ?
        <div className="toast-container top-0 start-50 translate-middle-x p-3" style={{
            zIndex: 1,
        }}
        >
            <div className="fade toast show" style={{ padding: '1rem' }} >
                <div >
                    <strong className="me-auto">Update Available</strong>
                    <small style={{ margin: '0px 5px' }}>candell.org</small>
                </div>
                <div><div>Please refresh your browser<button style={{
                    marginLeft: "20px",
                    padding: "0.1rem",
                    borderWidth: "0 0 1px 0",
                    background: "rgb(230,240,230)"
                }
                } onClick={() => location.reload()}>Refresh</button></div></div>
            </div>
        </div> : null
    );
}
function Logo() {
    const [params] = useSearchParams();
    const [beta] = useState(!!params.get("beta"));

    return <div className="logo animate blur">{beta ? "Beta" : ""}</div>

}
interface ArticleWrapProps {
    route?: LinkInfo,
    routeName?: string,
    noLog?: boolean
}
function ArticleWrap({ route, routeName, noLog }: ArticleWrapProps) {
    const { pageName: paramPageName } = useParams();

    let pageRoute = route;
    const pageName = routeName || paramPageName;
    if (!route && pageName) {
        pageRoute = myRoutes.find((r) => r.LinkName === pageName)
    }
    const navState = useNavigation();
    useEffect(() => {
        document.title = `Eric Candell--${pageRoute?.FullName || ""}`;
    }, [pageRoute?.FullName]);
    useEffect(() => {
        const debug: boolean = document.cookie.search(/debug/) !== -1 || window.location.host.search(/localhost/) !== -1;
        if (!noLog && !debug) sendLogEvent(pageRoute?.LinkName || 'none')
    }, [noLog, pageRoute?.LinkName]);

    if (!pageRoute)
        return <Navigate to='/practice' replace />;
    else {

        return (
            <>
                <HeadingTitle route={pageRoute} />
                <article className="article">
                    <section className={navState.state === 'loading' ? 'loading' : ''} id="content">
                        <Suspense fallback={<Loading />}>
                            < ErrorBoundary>
                                {pageRoute.Page}
                            </ErrorBoundary>
                        </Suspense>
                    </section>
                </article>
            </>
        )
    }
}

function Home() {
    return (
        <article>
            <Helmet>
                <meta name="description" content="Eric Candell Therapy and Couples Counseling in Seattle Area" />
                <link rel="canonical" href="https://www.candell.org/practice" />
            </Helmet>
            <p className="topelement"><img src="https://www.candell.org/images/EricCan.jpg" alt="" style={{ width: '11.56rem' }} className="alignright" /><b> Are you looking for a therapist, marriage counselor or couples counselor in Seattle?</b> <b> Have you decided to make improvements in your life but don&apos;t know how to identify the right person to assist you?</b></p>
            <p>Hello! I am Eric Candell, a Seattle-based therapist and Licensed Mental Health Counselor (LMHC). I want to help you in your search for a mental health professional who fits your needs. To do so, I have put together some information for you about finding and working with therapists in our area.</p>
            <p>If you are already interviewing therapists, I highly recommend that you look at the information that I have compiled about <NavLink to={`${practiceDir}choose`}>Choosing a Seattle Therapist</NavLink>. Also, you can read more <NavLink to={`${practiceDir}seattle-therapist`}>about me</NavLink>.</p>
            <p>I invite you to explore my site and to find out more about the benefits of therapy and counseling. Please feel free to contact me if you have other questions. You may also go directly to information on <NavLink to={`${practiceDir}indiv`} > Individual Therapy</NavLink>, <NavLink to={`${practiceDir}seattle-couples-counseling`}>Couples Therapy</NavLink>, or integrated <NavLink to={`${practiceDir}seattle-couples-counseling`}>Marriage and Sex Counseling</NavLink>.</p>
            {/*<YoutubeEmbed embedId='KUhgJj2KLMQ' />*/}
        </article>
    );
}

function SigninPage() {
    const loc = useLocation() as Location<null>;
    const nav = useNavigate();
    const userContext = useUserInfo();
    const [, setUserInfo] = userContext.userInfo;
    let id_token: string | null = null;
    let redirectState = practiceDir + 'freebusy';
    if (loc.hash) {
        const sp = new URLSearchParams(loc.hash.slice(1));
        id_token = sp.get('id_token') || '';
        if (sp.get('state'))
            redirectState = atob(sp.get('state') || '');
    } else {
        //debugging
    }
    useEffect(() => {
        if (id_token) {
            const jwtObj = JSON.parse(atob(id_token.split('.')[1])) as { email: string, exp: number, picture?: string };
            sendLogEvent(`Successful sign-in for ${jwtObj.email}`);
            setUserInfo({ signedIn: true, email: jwtObj.email, picURL: jwtObj.picture || "", id_token, tokenExp: jwtObj.exp * 1000 });
            nav(redirectState, { replace: true });
        }
    }, [id_token, nav, redirectState, setUserInfo])
    return (
        id_token ? <article><Loading altText="Signing in..." /></article> :
            <article>
                {/* {<div>{atob(param.get('id_token')?.split('.')[1])}</div>}*/}
                {/*<div>{jwtObj?"aud:"+jwtObj.aud+" email:" +jwtObj.email + " sub:"+ jwtObj.sub:""}</div>*/}
                <div>You have signed out successfully.</div>
                <SigninLink redirect={redirectState} />
            </article>
    )
}
const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
    <div className="video-responsive">
        <iframe style={{ border: 0 }}
            width="420"
            height="345"
            src={`https://www.youtube.com/embed/${embedId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Youtube video"
        />
    </div>
);

const GroupTherapy = lazy(() => import('./group').catch(errorLoading));
const ContactArticle = lazy(() => import('./contact').catch(errorLoading));
const FeesArticle = lazy(() => import('./fees').catch(errorLoading));
const FaqArticle = lazy(() => import('./faq').catch(errorLoading));
const FirstArticle = lazy(() => import('./first').catch(errorLoading));
const CouplesArticle = lazy(() => import('./couples').catch(errorLoading));
const RequestInfo = lazy(() => import('./request').catch(errorLoading));
const ChooseArticle = lazy(() => import('./choose').catch(errorLoading));
const DiscloseArticle = lazy(() => import('./disclose').catch(errorLoading));
const IndivArticle = lazy(() => import('./indiv').catch(errorLoading));
const WhoIAmArticle = lazy(() => import('./whoIam').catch(errorLoading));
const FamilyArticle = lazy(() => import('./family').catch(errorLoading));
const AssessArticle = lazy(() => import('./seattle-therapist').then((module) => ({ default: module.AssessArticle })).catch(errorLoading));
const SuperviseArticle = lazy(() => import('./seattle-therapist').then((module) => ({ default: module.SuperviseArticle })).catch(errorLoading));
const freebusyLazy = () => import('./freebusy').then((module) => {
    const fbRoute: LinkInfo = { LinkName: "freebusy", FullName: "Schedule an Appointment", Page: <module.FreebusyArticle /> };
    const element = <ArticleWrap route={fbRoute} />;
    const loader = module.fbloader;
    return { loader, element }
})
const paymentLazy = () => import('./payment').then((module) => {
    const fbRoute: LinkInfo = { LinkName: "payment", FullName: "Payment", Page: <module.PaymentArticle /> };
    const element = <ArticleWrap route={fbRoute} />;
    const loader = module.pmtLoader;
    return { loader, element }
})
const cancelLazy = () => import('./freebusy').then((module) => ({
    //combines one lazy load for element and loader through router
    loader: module.cancelLoader,
    element: <ArticleWrap route={{
        FullName: "Cancel Appointment",
        LinkName: "cancel",
        Page: < module.CancelPage />
    }} />
}))
function errorLoading(e: Error) {
    console.log(`lazy load didn't complete: ${e.message}`);
    return Promise.reject(e)
}

interface LinkInfo {
    FullName: string;
    LinkName: string
    Page: JSX.Element;
}
const myRoutes: LinkInfo[] = [
    {
        FullName: "Home",
        LinkName: "home",
        Page: <Home />
    },
    {
        FullName: "Contact Information",
        LinkName: "contact",
        Page: <ContactArticle />
    },
    {
        FullName: "Choosing a Therapist",
        LinkName: "choose",
        Page: <ChooseArticle />
    },
    {
        FullName: "Couples Therapy",
        LinkName: "seattle-couples-counseling",
        Page: <CouplesArticle />
    },
    {
        FullName: "Individual Therapy",
        LinkName: "indiv",
        Page: <IndivArticle />
    },
    {
        FullName: "Sign In",
        LinkName: "login",
        Page: <SigninPage />
    },
    {
        FullName: "Group Therapy",
        LinkName: "group",
        Page: <GroupTherapy />
    },
    {
        FullName: "Fees",
        LinkName: "fees",
        Page: <FeesArticle />
    },
    {
        FullName: "Assessments",
        LinkName: "assess",
        Page: <AssessArticle />
    },
    {
        FullName: "Frequently Asked Questions",
        LinkName: "faq",
        Page: <FaqArticle />
    },
    {
        FullName: "Family Therapy",
        LinkName: "family",
        Page: <FamilyArticle />
    },
    {
        FullName: "Request Information",
        LinkName: "new-request",
        Page: <RequestInfo />
    },
    {
        FullName: "Your First Session",
        LinkName: "first",
        Page: <FirstArticle />
    },
    {
        FullName: "Sex Therapy",
        LinkName: "sex-therapy",
        Page: <CouplesArticle />
    },
    {
        FullName: "Views About Therapy",
        LinkName: "disclose",
        Page: <DiscloseArticle />
    },
    {
        FullName: "Who I Am",
        LinkName: "seattle-therapist",
        Page: <WhoIAmArticle />
    },
    {
        FullName: "Supervision for Other Therapists",
        LinkName: "supervise",
        Page: <SuperviseArticle />
    },
    {
        FullName: "Error",
        LinkName: "error",
        Page: <ErrorText />
    }
]

export const enum msgCode {
    OK,
    Past,
    NotFound,
    NotFree
}

function ForwardToPractice() {
    const { "*": nakedURL } = useParams();
    return (
        <Navigate to={`${practiceDir}${nakedURL || ''}`} replace />
    )
}
function HeadingTitle(props: { route: LinkInfo }) {

    return (
        <div className="pagetitle">
            <span id="titleText" className="titletext" >
                {props.route.FullName}
            </span>
        </div>
    )
}

const SubMenu= memo(function SubMenu(props: { children?: React.ReactNode, menuTitle: string }) {
    return (
        <li>
            <a className="menuname" onClick={() => true}>{props.menuTitle}</a>
            <ul >
                {props.children}
            </ul >
        </li >
    )
})
const setActiveStyle = ({ isActive }: { isActive: boolean }) => (isActive ? " selected" : "");
const NavBarLink = memo(function NavBarLink (props: { linkName: string, fullName?: string, className?: string }) {
    const myTitle = props.fullName || myRoutes.find(i => i.LinkName === props.linkName)?.FullName || '';
    return (<li>
        <NavLink unstable_viewTransition to={`${practiceDir}${props.linkName}`} className={props.className || setActiveStyle}>{myTitle}</NavLink>
    </li>)
})
function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    //pick up clicks outside of the menu and button
    const menuRef = useRef<HTMLElement>(null);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
            if ((menuRef.current && event.target && !menuRef.current.contains(event.target as Node)) && (menuButtonRef.current && !menuButtonRef.current.contains(event.target as Node))) {

                setMenuOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, menuButtonRef]);
    return (<div id="menuWrap">
        <nav ref={menuRef} className={"menu" + (menuOpen ? " menuOpen" : "")} id="mainMenu" onClick={() => setMenuOpen(false)}>
            <ul id="menulist">
                <li>
                    <NavLink unstable_viewTransition className="menuname" to="/practice">Home</NavLink>
                    <ul>
                    </ul>
                </li>

                <SubMenu menuTitle="Getting Started">
                    <NavBarLink linkName='choose' />
                    <NavBarLink linkName='first' />
                    <NavBarLink linkName='new-request' />
                    <NavBarLink linkName='faq' fullName='Frequent Questions' />
                    <FreebusyLink />
                </SubMenu>
                <SubMenu menuTitle="About Me">
                    <NavBarLink linkName='seattle-therapist' />
                    <NavBarLink linkName='disclose' />
                    <NavBarLink linkName='contact' />
                </SubMenu>
                <SubMenu menuTitle="Services">
                    <NavBarLink linkName='indiv' fullName='Individual Therapy Seattle' />
                    <NavBarLink linkName='seattle-couples-counseling' fullName='Couples Therapy Seattle' />
                    <NavBarLink linkName='sex-therapy' fullName='Sex Therapy Seattle' />
                    <NavBarLink linkName='group' />
                    <NavBarLink linkName='family' />
                    <NavBarLink linkName='assess' />
                    <NavBarLink linkName='supervise' fullName='Supervision for Therapists' />
                    <NavBarLink linkName='fees' />
                </SubMenu>

            </ul>
        </nav>
        <button ref={menuButtonRef} id="menuButton" aria-label="Navigation Menu" type="button" onClick={() => setMenuOpen(m => !m)}>
            <img src="https://www.candell.org/images/menuhamburger.png" style={{ height: "1.75rem" }} />
        </button>
    </div>
    );
}
function FreebusyLink() {
    const [schedAvail] = useSchedAvail();
    return (
        schedAvail ?
            < NavBarLink linkName='freebusy' fullName='Schedule Appointment' /> : null
    )
}
export const ContactMe = memo(function ContactMe() {
   return (<p>You may <Link unstable_viewTransition to={`${practiceDir}new-request`}>request more information </Link>online, or to set up an initial session, <Link unstable_viewTransition to={`${practiceDir}contact`}>call me</Link> at my office.</p>)
})
export const Loading = memo(function Loading(props: { altText?: string }) {
    return <div> {props.altText || 'Loading...'}<img src="https://www.candell.org/images/spinner.svg" className="noshadow" style={{ width: '2rem' }} /></div>;
})
function sendLogEvent(s: string) {
    //return; //this needs to be removed for production
    //even though lambda logging is non-essential, it does help with cold starts of the lambda function for scheduling
    if (/freebusy/.test(s) || /sign-in/.test(s) || /login/.test(s))
        fetch("https://api.candell.org/logEvent",
            {
                mode: 'cors',
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },

                body: JSON.stringify({ logText: s, SessionId })
            }).catch((r) => console.log(r));
    sessionEstablished.then(() => fetch(practiceDir + "api/logEvent",
        {
            mode: 'cors',
            method: "POST",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
            },

            body: JSON.stringify({ logText: s })
        })).catch((r) => console.log(r));
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path='/' element={<Navigate to='practice' />} errorElement={<RouterError />} />
            <Route path='disttest/:tp' element={<Navigate to={`${practiceDir}cancel/durmin60`} />} />
            <Route path='practice/*' element={<PracticeApp />} errorElement={<RouterError />} id="app" >
                <Route index element={<ArticleWrap routeName='home' />} />
                <Route path='freebusy/durmin60' element={<Navigate to={`${practiceDir}cancel/durmin60`} replace />} />
                <Route path='cancel/:cancelID' lazy={cancelLazy} />
                <Route path='payment/:clientId/:token' lazy={paymentLazy} />
                <Route path='freebusy/:durmin?' lazy={freebusyLazy} />
                <Route path=":pageName" element={<ArticleWrap />} />
            </Route>
            <Route path='/*' element={<ForwardToPractice />} />
        </>), {
            future: {
                v7_relativeSplatPath: true,
                unstable_skipActionErrorRevalidation: true
            }
})

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(<StrictMode>
        <RouterProvider router={router} future={{
            v7_startTransition: true
        }} />
    </StrictMode>);
}
//ReactDOM.render(<RouterApp />, document.getElementById('root'));

